import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const numberChageDate = new Date('2017-11-01T00:00:00.000');

const MegaMillBody = ({isMobile}) => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState(null);
  const [dataNumbers, setDataNumbers] = useState(null);
  const [megaBallNumbers, setMegaBallNumbers] = useState(null);
  const [searchAmount, setSearchAmount] = useState(1000);
  const [isLoading, setIsLoading] = useState(false)
  const [sums, setSums] = useState(null);
  const [averageSums, setAverageSums] = useState(0);

  const columns = [
    {
      field: 'draw_date',
      headerName: 'Draw Date',
      width: 130,
      editable: false,
      valueGetter: (value, row) => {
        var mydate = new Date(value);
        return mydate.toDateString();
      },
    },
    {
      field: 'winning_numbers',
      headerName: 'Winning Numbers',
      width: 140,
      editable: false,
      type: "number"
    },
    {
      field: 'mega_ball',
      headerName: 'Megaball',
      width: 100,
      editable: false,
      type: "number"
    },
    {
      field: 'multiplier',
      headerName: 'Multiplier',
      width: 100,
      editable: false,
      type: "number"
    }
  ];
  
  const columnsLastDrawn = [
    {
      field: 'id',
      headerName: 'Number',
      width: 150,
      editable: false
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 150,
      editable: false
    },
    {
      field: 'date',
      headerName: 'Last Time Drawn',
      width: 200,
      editable: false,
      type: 'Date',
      valueGetter: (value, row) => {
        var mydate = new Date(value);
        return mydate.toDateString();
      },
    },
  ];

  const columnSums = [
    {
      field: 'date',
      headerName: 'Draw Date',
      width: 200,
      editable: false,
      type: 'Date',
      valueGetter: (value, row) => {
        var mydate = new Date(value);
        return mydate.toDateString();
      },
    },
    {
      field: 'sum',
      headerName: 'Sum',
      width: 150,
      editable: false,
      type: "number"
    },
    {
      field: 'average',
      headerName: 'Average Sum: '+ averageSums,
      width: 200,
      editable: false,
      type: "number",
      sortable: false,
    }
  ];
  
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeInput = (value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      if (value > 1000) {
        setSearchAmount(1000);
      } else if (value < 1) {
        setSearchAmount("");
      } else {
        setSearchAmount(value);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      fetch('https://data.ny.gov/resource/5xaw-6ayf.json?%24limit='+searchAmount.toString())
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          setIsLoading(false);    
        })
      }
    fetchData()
    //eslint-disable-next-line
  },[])

  const newSearch = () => {
    setIsLoading(true);
    const fetchData = async () => {
      if (searchAmount === "") {
        setSearchAmount(1000);
      }
      fetch('https://data.ny.gov/resource/5xaw-6ayf.json?%24limit='+searchAmount.toString())
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          setIsLoading(false);    
        })
      }
    fetchData();
  }

  useEffect(() => {
    if (data) {
      const temp = [];
      const tempMega = [];
      const tempSum = [];
      let tempAvSums = 0;
      data.forEach((xValue, xIndex) => {
        if (numberChageDate < new Date(xValue["draw_date"])) {
          const split = xValue["winning_numbers"].split(" ");
          let sums = 0;
          split.forEach((yValue,yIndex) => {
            sums += Number(yValue);
            const found = temp.find(o => o?.['id'] === yValue);
            if (found) {
              temp[found.index]["total"] += 1;
            } else {
              temp.push({
                "id": yValue,
                "date": xValue["draw_date"],
                "total": 1,
                "index": (temp?.length? temp?.length:0)
              })
            }
          })
          tempAvSums += sums;
          tempSum.push({
            "date": xValue["draw_date"],
            "sum": sums,
          })

          const foundMegaBall = tempMega.find(o => o?.['id'].padStart(2, '0') === xValue["mega_ball"].padStart(2, '0'));
          if (foundMegaBall) {
            tempMega[foundMegaBall.index]["total"] += 1;
          } else {
            tempMega.push({
              "id": xValue["mega_ball"].padStart(2, '0'),
              "date": xValue["draw_date"],
              "total": 1,
              "index": (tempMega?.length? tempMega?.length:0)
            })
          }

        }
      });
      setAverageSums(Math.round(tempAvSums/tempSum.length))
      setSums(tempSum);
      setDataNumbers(temp);
      setMegaBallNumbers(tempMega);
    }
  }, [data])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading ? 
      <Grid container spacing={1} sx={{margin:'10px'}}>
        <Grid size={(isMobile?0:2)}>
        </Grid>
        <Grid size={(isMobile?12:8)} sx={{textAlign:'center'}}>
          <CircularProgress color="secondary" />
        </Grid>
        <Grid size={(isMobile?0:2)}>
        </Grid>
      </Grid>
       : 
      <>
        <Grid container spacing={1} sx={{margin:'10px'}}>
          <Grid size={(isMobile?0:2)}>
          </Grid>
          <Grid size={(isMobile?12:8)}>
            <Grid container sx={{textAlign:'center'}}>
              <Grid size={(6)}>
                <h2 style={{margin:'0'}}>Mega Millions</h2>
              </Grid>
              <Grid size={(6)}>
                  <TextField
                    label="Between 1 and 1000"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    onChange={(event) => handleChangeInput(event.target.value)}
                    value={searchAmount}
                    sx={{paddingRight:'2px'}}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        newSearch();
                      }
                    }}
                  />
                <Button 
                  variant="contained"
                  onClick={newSearch}>
                    Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={(isMobile?0:2)}>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{margin:'10px'}}>
          <Grid size={(isMobile?0:2)}>
          </Grid>
          <Grid size={(isMobile?12:8)} sx={{border:1, borderRadius:2}}>
            <Box sx={{ width: '100%', padding:'2ex' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Draws" {...a11yProps(0)} />
                  <Tab label="Totals" {...a11yProps(1)} />
                  <Tab label="MegaBall Totals" {...a11yProps(2)} />
                  <Tab label="Sums" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100, { value: -1, label: 'All' }]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row?.['draw_date']}
                    sx={{maxHeight:'632px'}}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <DataGrid
                    rows={dataNumbers}
                    columns={columnsLastDrawn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row?.['id']}
                    sx={{maxHeight:'632px'}}
                  />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <DataGrid
                  rows={megaBallNumbers}
                  columns={columnsLastDrawn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  disableRowSelectionOnClick
                  getRowId={(row) => row?.['id']}
                  sx={{maxHeight:'632px'}}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DataGrid
                  rows={sums}
                  columns={columnSums}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  disableRowSelectionOnClick
                  getRowId={(row) => row?.['date']}
                  sx={{maxHeight:'632px'}}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid size={(isMobile?0:2)}>
          </Grid>
        </Grid>
      </>
      }
      
    </>
    
  );
}

export default MegaMillBody;